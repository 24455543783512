<template>
  <v-dialog
    v-model="model"
    transition="scale-transition"
    :width="1200"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-card
      class="elevation-3 d-flex flex-column"
      :loading="loading"
    >
      <v-card-title>
        <span class="secondary--text">
          {{ $t('messages.views.admin.components.core.settingsModal.title') }}
        </span>

        <v-spacer />

        <span>
          <v-btn
            icon
            @click="$emit('close')"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </span>
      </v-card-title>

      <v-card-text
        class="flex-grow-0"
      >
        <o-form
          v-if="settings"
          ref="form"
          @submit="submit"
        >
          <div class="d-flex justify-end pr-14">
            <label class="pa-2">Notification</label>
            <label class="pa-2">Email</label>
          </div>
          <v-expansion-panels accordion>
            <v-expansion-panel
              v-for="(settings, category) of form.settings"
              :key="`category-${category}`"
            >
              <v-expansion-panel-header>
                <h3>{{ $t('constants.setting.category.' + category) }}</h3>
                <div class="d-flex justify-end">
                  <div class="pa-2">
                    <v-switch
                      hide-details="auto"
                      :input-value="isAllCategoryNotificationChecked(category, 'notification')"
                      color="secondary"
                      @click.stop
                      @change="toggleAll(category, 'notification')"
                    />
                  </div>
                  <div class="pa-2">
                    <v-switch
                      hide-details="auto"
                      :input-value="isAllCategoryNotificationChecked(category, 'email')"
                      color="secondary"
                      @click.stop
                      @change="toggleAll(category, 'email')"
                    />
                  </div>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div
                  v-for="(setting, type) of settings"
                  :key="`setting-${type}`"
                  class="d-flex justify-space-between align-center notification-row"
                >
                  <div>
                    {{ $t('constants.activity.event.type.' + type) }}
                  </div>
                  <div class="d-flex pr-6">
                    <div class="pa-2 align-center">
                      <v-switch
                        v-model="form.settings[category][type].notification"
                        hide-details="auto"
                        color="secondary"
                        class="mt-0"
                        @change="$refs.form.onFormChanged()"
                      />
                    </div>
                    <div class="pa-2">
                      <v-switch
                        v-model="form.settings[category][type].email"
                        hide-details="auto"
                        color="secondary"
                        class="mt-0"
                        @change="$refs.form.onFormChanged()"
                      />
                    </div>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <o-submit class="mt-2" />
        </o-form>
        <o-loader v-else />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      value: {
        type: Boolean,
        default: null,
      },
    },

    data: function () {
      return {
        loading: true,
        validation: require('@validation/entities/setting.json'),
        form: {
          settings: {},
        },
        categories: {
          CROSSROAD: [
            'CROSSROAD_USER_STATE_CHANGED',
            'CROSSROAD_STATION_WATCHER_STATE_CHANGED',
            'CROSSROAD_STATION_STATE_CHANGED',
            'CROSSROAD_OUT_OF_CONTROL',
            'CROSSROAD_IN_CONTROL',
          ],
          CONNECTIVITY: [
            'STATION_SYNCHRONIZED',
            'STATION_OUT_OF_SYNC',
            'STATION_CONNECTION_FAILED',
            'STATION_CONNECTION_SUCCESS',
            'STATION_CONNECTION_CLOSED',
          ],
          USER: [
            'USER_AUTHENTICATED',
          ],
          WAMP: [
            'WAMP_CONNECTION_FAILED',
            'WAMP_CONNECTION_SUCCESS',
            'WAMP_CONNECTION_CLOSED',
            'WAMP_AUTHENTICATION_FAILED',
            'WAMP_AUTHENTICATION_SUCCESS',
            'WAMP_AUTHORIZATION_FAILED',
          ],
          DIASER: [
            'DIASER_EVENT_DEFECT_CREATED',
            'DIASER_MINOR_DEFECT_CREATED',
            'DIASER_MAJOR_DEFECT_CREATED',
            'DIASER_EVENT_DEFECT_UPDATED',
            'DIASER_MINOR_DEFECT_UPDATED',
            'DIASER_MAJOR_DEFECT_UPDATED',
          ],
        },
      };
    },

    computed: {
      model: {
        get () {
          return this.value;
        },
        set (model) {
          this.$emit('input', model);
        },
      },
    },

    created () {
      this.fetchFormSettings();
    },

    apollo: {
      settings: {
        query: require('@gql/views/admin/components/core/settings-modal/getSettings.gql'),
        client: 'activity-thread',
        fetchPolicy: 'no-cache',
        result (res, key) {
          this.loading = res.loading;
        },
        update (data) {
          for (const setting of data.getSettings) {
            for (const [category] of Object.entries(this.form.settings)) {
              if (this.form.settings[category][setting.type] === undefined) {
                continue;
              }
              this.form.settings[category][setting.type].notification = setting.notification;
              this.form.settings[category][setting.type].email = setting.email;
            }
          }

          return data.getSettings;
        },
      },
    },

    methods: {
      async fetchFormSettings () {
        const response = await this.$apollo.query({
          query: require('@gql/views/admin/components/core/settings-modal/getDefaultSettings.gql'),
          client: 'activity-thread',
          fetchPolicy: 'no-cache',
        });

        const typeToCategory = {};
        for (const [category, types] of Object.entries(this.categories)) {
          for (const type of types) {
            typeToCategory[type] = category;
          }
        }

        this.form.settings = {};
        for (const defaultSetting of response.data.getDefaultSettings) {
          const category = typeToCategory[defaultSetting.type];
          if (!this.form.settings[category]) {
            this.$set(this.form.settings, category, {});
          }
          this.$set(this.form.settings[category], defaultSetting.type, {
            notification: defaultSetting.notification,
            email: defaultSetting.email,
          });
        }

        await this.fetchSettings();
      },
      async fetchSettings () {
        const response = await this.$apollo.query({
          query: require('@gql/views/admin/components/core/settings-modal/getSettings.gql'),
          client: 'activity-thread',
          fetchPolicy: 'no-cache',
        });

        this.loading = response.loading;

        const data = response.data.getSettings;
        for (const setting of data) {
          for (const [category] of Object.entries(this.form.settings)) {
            if (this.form.settings[category][setting.type] === undefined) {
              continue;
            }
            this.form.settings[category][setting.type].notification = setting.notification;
            this.form.settings[category][setting.type].email = setting.email;
          }
        }
      },
      isAllCategoryNotificationChecked (category, type) {
        return Object.values(this.form.settings[category]).every(setting => setting[type]);
      },
      submit () {
        this.loading = true;
        this.$apollo.mutate({
          mutation: require('@gql/mutations/setting/updateSettings.gql'),
          client: 'activity-thread',
          variables: {
            input: {
              settings: Object.entries(this.form.settings).flatMap(([category, settings]) => {
                return Object.entries(settings).map(([type, value]) => ({
                  type: type,
                  category: 'EVENT',
                  ...value,
                }));
              }),
            },
          },
        }).then((result) => {
          this.$flash('messages.views.admin.components.core.settingsModal.submit.success', 'success');
          this.model = false;
        }).catch((e) => {
          this.$flash(null, 'error');
        }).finally(() => {
          this.loading = false;
        });
      },

      toggleAll (category, type) {
        const newValue = !this.isAllCategoryNotificationChecked(category, type);
        for (const setting in this.form.settings[category]) {
          this.$set(this.form.settings[category][setting], type, newValue);
        }
        this.$refs.form.onFormChanged();
      },
    },
  };
</script>

<style lang="sass" scoped>
  .notification-row:not(:first-child)
    border-top: solid 1px #BDBDBD
</style>
